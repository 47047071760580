<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.FAULTEXPORT"
              :innerAPI="this.proApis.FAULTIMPORT"
              :API="this.proApis.FAULTIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <el-select clearable v-model="faultType" placeholder="故障类型" @change="getFaultTypeId" size="small" style="width:150px !important;marginRight: 5px;">
            <el-option v-for="item in FaultTypeAllList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select clearable v-model="faultCode" placeholder="故障代码" size="small" style="width:150px !important;marginRight: 5px;">
            <el-option v-for="item in faultCodeList " :key="item.id" :label="item.code" :value="item.id"></el-option>
            </el-select>
            <el-input v-model="nameOrCode" :placeholder="$t('EQUIPMENT NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        
        <!-- <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
        </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove} from "@/api/warnList"
import importBtn from "@/components/importExport/importBtn";
import {getMechinaAll, getBZAll,getFaultTypeAll,getGroupUser,getFaultCodeBytType} from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            faultType: "",
            FaultTypeAllList: [],
            faultCode: "",
            faultCodeList: [],
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                labelWidth: 150,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('SELECT DEVICE'),
                    dicData: [],
                    hide: true,
                    type: "select",
                    prop: "machineId",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                        trigger: "blur"
                    }]
                },
                {
                    label: this.$t('FAULT ORDER NO'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "id"
                },
                {
                    label: this.$t('FAULT TEAM'),
                    dicData: [],
                    type: "select",
                    prop: "departmentId",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER TEAM NAME'),
                        trigger: "blur"
                    }]
                },
                {
                    label: this.$t('EQUIPMENT NAME'),
                    display: false,
                    prop: "machineName",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                        trigger: "blur"
                    }]
                },
                {
                    label: this.$t('EQUIPMENT CODE'),
                    display: false,
                    prop: "machineCode"
                },
                {
                    label: this.$t('FAULT TYPE'),
                    dicData: [],
                    type: "select",
                    prop: "type",
                    props: {
                        label: "name",
                        value: "id"
                    },
                       change: (value)=> {
                        console.log(value);
                        getFaultCodeBytType(value.value).then(res =>{
                            console.log(res);
                          this.tableOption.column[6].dicData = res.data.data;
                        })
                    },
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER FAULT TYPE'),
                        trigger: "blur"
                    }]
                }, 
                {
                    label: this.$t('FAULT RECORD NO'),
                     dicData: [],
                    type: "select",
                    prop: "faultInformationId",
                    props: {
                        label: "code",
                        value: "id"
                    },
                    formatter: function (row) {
                        return row.faultInformationCode;
                    },
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE CODE'),
                        trigger: "blur"
                    }]
                },
                {

                    label: this.$t('FAULT REMARK'),
                    prop: "remarks"
                }, 
                {
                    label: this.$t('START TIME'),
                    type: "datetime",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE START TIME'),
                        trigger: "blur"
                    }],
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    prop: "beginTime"
                }, 
                {
                    label: this.$t('HEAD USER'),
                    dicData: [],
                    type: "select",
                    filterable: true,
                    prop: "confirmUserid",
                    props: {
                        label: "name",
                        value: "userId"
                    },
                    formatter: function (row) {
                        return row.confirmUsersName;
                    },
                     rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT THE HEAD USER'),
                        trigger: "blur"
                    }]
                },  
                {
                    label: this.$t('DISPOSAL COMPLETED'),
                    type: "radio",
                    prop: "isFinish",
                    dicData: [{
                        label: this.$t('True'),
                        value: 1
                    }, {
                        label: this.$t('False'),
                        value: 0
                    }],
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT WHETHER THE DISPOSAL IS COMPLETED'),
                        trigger: "blur"
                    }]
                }, 
                // {
                //     label: this.$t('CREATTIME'),
                //     width: 100,
                //     addDisplay: false,
                //     editDisplay: false,
                //     prop: "createTime"
                // }, 
                {
                    label: this.$t('CREATE USER'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "createUsersName"
                },
                // {
                //     label: this.$t('PROCESSING COMPLETION CONFIRMATION TIME'),
                //     valueFormat: "yyyy-MM-dd hh:mm:ss",
                //     width: 110,
                //     type: "datetime",
                //     prop: "confirmTime"
                // }, 
                {
                    label: this.$t('END TIME'),
                    type: "datetime",
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    prop: "endTime"
                }]
            }
        }
    },
    created() {
        //所有的设备
      getMechinaAll().then(res => {
        this.tableOption.column[0].dicData = res.data.data;
      });
      //所有的班组
        getBZAll().then(res => {
            this.tableOption.column[2].dicData = res.data.data;
        });
        //所有故障类型  
         getFaultTypeAll().then(res => {
             this.FaultTypeAllList = res.data.data
            this.tableOption.column[5].dicData = res.data.data;
        });
        //班组下的人员
         getGroupUser().then(res => {
            this.tableOption.column[9].dicData = res.data.data;
        });
      this.list();
    },
    methods: {
            getFaultTypeId(vel){
            console.log('vel',vel);
            getFaultCodeBytType(vel).then(res => {
              console.log(res);
              this.faultCodeList = res.data.data
          })
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(() => {
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            console.log(row);
            edit(row).then((res) => {
                console.log(res);
                this.list();
                done();
            })
        },
        handleSave(row, done) {
            console.log(row)
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          this.tableData = [];
          list(this.page.pageCurrent,this.page.pageSize,this.faultType,this.faultCode,this.nameOrCode).then(res => {
              console.log(res);
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>